import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from 'react-router-dom';

import { AuthProvider, useAuth } from 'hooks';
import ContainerDeMensagensFlutuantes from '@bit/digix.digixui.mensagens-flutuantes';
import { importarRota, MotionWrapper, RouterCreator } from 'router';
import { routesConfig } from 'config';
import { Permissoes } from 'enums/permissoes';
import { ReactNode } from 'react';

import 'digix-ui/ativos/sass/temas/papel-zero.scss';
import { AppLayout } from 'layouts';
import { AnimatePresence } from 'framer-motion';
import ConteudoIndisponivel from 'components/ConteudoIndisponivel';
import Erro from 'pages/ConsultaAtendimento.fa.$id/Erro';

export function RotaPrivada({
  children,
  permissoes,
}: {
  children: ReactNode;
  permissoes: Permissoes[];
}) {
  const { usuario, podeAcessar } = useAuth();

  if (!usuario || !podeAcessar(permissoes, { temQualquerPermissao: true })) {
    return <Navigate to="/app/aplicativos" />;
  }

  return <MotionWrapper>{children}</MotionWrapper>;
}

const router = createBrowserRouter([
  {
    element: (
      <>
        <AuthProvider>
          <Outlet />
        </AuthProvider>
      </>
    ),
    children: [
      {
        path: '*',
        element: (
          <RouterCreator
            routesConfig={routesConfig}
            redirectPath="/app/aplicativos"
            fallbackRedirectPath="/"
          />
        ),
      },
      {
        element: (
          <AnimatePresence initial exitBeforeEnter>
            <AppLayout />
          </AnimatePresence>
        ),
        children: [
          {
            path: '/app/configuracao/',
            errorElement: <ConteudoIndisponivel />,
            lazy: importarRota('Configuracao'),
            children: [
              {
                path: 'agendas',
                errorElement: <ConteudoIndisponivel />,
                lazy: importarRota('ConfiguracaoAgendas'),
              },
              {
                path: 'unidades',
                errorElement: <ConteudoIndisponivel />,
                lazy: importarRota('ConfiguracaoUnidades'),
              },
            ],
          },
          {
            path: '/app/configuracao/unidades/:id',
            errorElement: <ConteudoIndisponivel />,
            lazy: importarRota('ConfiguracaoUnidades.$ID'),
          },
          {
            path: '/app/configuracao/agendas/:id',
            errorElement: <ConteudoIndisponivel />,
            lazy: importarRota('ConfiguracoesDoSistema'),
            children: [
              {
                path: 'geral',
                errorElement: <ConteudoIndisponivel />,
                lazy: importarRota('ConfiguracoesDoSistema.Geral'),
              },
              {
                path: 'feriados',
                errorElement: <ConteudoIndisponivel />,
                lazy: importarRota('ConfiguracoesDoSistema.Feriados'),
              },
              {
                path: 'forca-tarefa',
                errorElement: <ConteudoIndisponivel />,
                lazy: importarRota('ConfiguracoesDoSistema.ForcaTarefa'),
              },
            ],
          },
          {
            path: '/app/consulta-atendimento',
            errorElement: <ConteudoIndisponivel />,
            lazy: importarRota('ConsultaAtendimento'),
            children: [
              {
                path: 'fa/:id',
                errorElement: <Erro />,
                lazy: importarRota('ConsultaAtendimento.fa.$id'),
              },
            ],
          },
        ],
      },
    ],
  },
]);

export default function App() {
  return (
    <>
      <ContainerDeMensagensFlutuantes />
      <RouterProvider router={router} />
    </>
  );
}
