import { useCallback, useMemo } from 'react';

import clsx from 'clsx';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

import { BotaoComTexto } from '@bit/digix.digixui.botao';

import logoEProcon from 'assets/images/e-procon-logo-branco.svg';
import logoDoSistema from 'assets/images/logo.png';
import { Permissoes } from 'enums';
import { useAuth } from 'hooks';
import { parsedLocalStorage } from 'utils';

import classes from './styles.module.scss';

export function AppLayout(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { deslogar, usuario, podeAcessar } = useAuth();

  const memoAplicativos = useMemo(() => {
    const { pathname } = location;

    return pathname === '/app/aplicativos';
  }, [location]);

  const memoMenuItems = useMemo(() => {
    const menuItems = [
      {
        id: 'fila-atendimento',
        path: '/app/fila-atendimento',
        nome: 'Fila de Atendimento',
        filtro: 'fila-atendimento',
        permissoes: [
          Permissoes.ATENDENTE,
          Permissoes.GESTOR_DE_FILA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
        ],
      },
      {
        id: 'consulta',
        path: '',
        nome: 'Consultas',
        filtro: 'consulta-atendimento',
        permissoes: [
          Permissoes.ATENDENTE,
          Permissoes.GESTOR_DE_FILA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
          Permissoes.CONCILIADOR,
          Permissoes.GESTOR_DE_AUDIENCIA,
        ],
      },
      {
        id: 'audiencias',
        path: '',
        nome: 'Audiências',
        filtro: 'audiencias',
        permissoes: [
          Permissoes.GESTOR_DE_AUDIENCIA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
          Permissoes.CONCILIADOR,
        ],
      },
      {
        id: 'relatorios',
        path: '',
        nome: 'Relatórios',
        filtro: 'audiencias',
        permissoes: [
          Permissoes.GESTOR_DE_FILA,
          Permissoes.GESTOR_DE_AUDIENCIA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
        ],
      },
    ];

    const opcoesPermissao = {
      temQualquerPermissao: true,
    };

    return menuItems.filter(menuItem =>
      !menuItem?.permissoes
        ? true
        : podeAcessar(menuItem.permissoes, opcoesPermissao),
    );
  }, [podeAcessar]);

  const memoMenuSubItems = useMemo(() => {
    const menuSubItems = [
      {
        idItem: 'consulta',
        id: 'consulta-atendimento',
        path: '/app/consulta-atendimento',
        nome: 'Atendimentos',
        icone: 'bullhorn',
        filtro: 'consulta-atendimento',
      },
      {
        idItem: 'consulta',
        id: 'consulta-audiencia',
        path: '/app/consulta-audiencia',
        nome: 'Audiências',
        icone: 'calendar',
        filtro: 'consulta-audiencia',
        permissoes: [
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
          Permissoes.GESTOR_DE_AUDIENCIA,
        ],
      },
      {
        idItem: 'consulta',
        id: 'fornecedores',
        path: '/app/fornecedores',
        nome: 'Fornecedores',
        icone: 'industry',
        filtro: 'fornecedores',
        permissoes: [
          Permissoes.ATENDENTE,
          Permissoes.GESTOR_DE_FILA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
          Permissoes.CONCILIADOR,
          Permissoes.GESTOR_DE_AUDIENCIA,
        ],
      },
      {
        idItem: 'audiencias',
        id: 'agenda-geral',
        path: '/app/agenda-geral',
        nome: 'Agenda Geral',
        icone: 'calendar',
        filtro: 'agenda-geral',
        dataTestId: 'botao-agenda-geral',
        permissoes: [
          Permissoes.GESTOR_DE_AUDIENCIA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
        ],
      },
      {
        idItem: 'audiencias',
        id: 'minhas-audiencias',
        path: '/app/minhas-audiencias',
        icone: 'calendar-alt',
        dataTestId: 'botao-minhas-audiencias',
        nome: 'Minhas audiências',
        filtro: 'minhas-audiencias',
        permissoes: [Permissoes.CONCILIADOR],
      },
      {
        idItem: 'relatorios',
        id: 'classificacao-de-atendimentos',
        path: '/app/relatorios/classificacao-de-atendimentos',
        nome: 'Classificação de Atendimentos',
        icone: 'file-lines',
        filtro: 'classificacao-de-atendimentos',
        dataTestId: 'botao-classificacao-de-atendimentos',
        permissoes: [
          Permissoes.GESTOR_DE_FILA,
          Permissoes.GESTOR_DE_AUDIENCIA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
        ],
      },
    ];

    const opcoesPermissao = {
      temQualquerPermissao: true,
    };

    return menuSubItems.filter(menuSubItem =>
      !menuSubItem?.permissoes
        ? true
        : podeAcessar(menuSubItem.permissoes, opcoesPermissao),
    );
  }, [podeAcessar]);

  const memoSubMenuItemsSecundario = useMemo(() => {
    const subMenuItemsSecundario = [
      {
        id: 'configuracoes-do-sistema',
        path: '/app/configuracao',
        nome: 'Configurações do sistema',
        filtro: 'configuracoes-do-sistema',
        dataTestId: 'botao-configuracoes-do-sistema',
        permissoes: [
          Permissoes.GESTOR_DE_AUDIENCIA,
          Permissoes.GESTOR_DE_FILA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
        ],
      },
    ];

    return subMenuItemsSecundario.filter(menuItemSecundario =>
      !menuItemSecundario?.permissoes
        ? false
        : podeAcessar(menuItemSecundario.permissoes, {
            temQualquerPermissao: true,
          }),
    );
  }, [podeAcessar]);

  const memoLogo: string = useMemo(() => {
    const logoInterna = memoAplicativos ? logoEProcon : logoDoSistema;

    return logoInterna;
  }, [memoAplicativos]);

  const memoEsconderNavegacao = useMemo(() => {
    return !!location.pathname.includes('aplicativos');
  }, [location.pathname]);

  const getMenuAtivo = useCallback(
    (path: string) => {
      return location.pathname.includes(path);
    },
    [location.pathname],
  );

  const handleNavigate = useCallback(
    (path: string) => () => {
      parsedLocalStorage.removeAllExceptUserData();
      navigate(path);
    },
    [navigate],
  );

  return (
    <>
      <header className="cabecalho-sistema">
        <div className="cabecalho-sistema__container-primario">
          <BotaoComTexto
            visual="sem-preenchimento"
            tipo="button"
            onClick={() =>
              navigate(
                `/app/${memoAplicativos ? 'aplicativos' : 'fila-atendimento'}`,
                {
                  replace: true,
                },
              )
            }
            classesAuxiliares={clsx(classes.botaoLogo, {
              [classes.paginaAplicativos]: memoAplicativos,
            })}
          >
            <img
              className="cabecalho-sistema__logo"
              width="33"
              src={memoLogo}
              alt="Logotipo do Atendimento"
            />
          </BotaoComTexto>
          {!memoEsconderNavegacao && (
            <nav
              className="cabecalho-sistema__menu"
              aria-label="Menu principal"
            >
              {memoMenuItems.map(menuItem => (
                <ul
                  className="cabecalho-sistema__lista-de-itens"
                  key={menuItem.id}
                >
                  {menuItem.path === '' ? (
                    <li
                      className="dropdown dropdown_direita"
                      key={`li-${menuItem.id}`}
                    >
                      <button
                        type="button"
                        className={clsx('cabecalho-sistema__item-de-menu', {
                          'cabecalho-sistema__item-de-menu_ativo': getMenuAtivo(
                            menuItem.filtro,
                          ),
                        })}
                        aria-label=""
                        aria-haspopup="true"
                      >
                        <div role="presentation">{menuItem.nome}</div>
                        {!menuItem.path && (
                          <i className="far fa-angle-down u-margem-esquerda-pequena" />
                        )}
                      </button>
                      <ul
                        className={clsx('dropdown__lista', classes.submenu)}
                        aria-label="Submenu"
                        key={`ul-${menuItem.id}`}
                      >
                        {memoMenuSubItems.map(
                          subMenuItem =>
                            subMenuItem.idItem === menuItem.id && (
                              <li
                                className="dropdown__item"
                                key={subMenuItem.id}
                              >
                                <BotaoComTexto
                                  cor="cinza"
                                  classesAuxiliares="dropdown__link"
                                  visual="sem-preenchimento"
                                  onClick={handleNavigate(subMenuItem.path)}
                                >
                                  <i
                                    className={`far fa-${subMenuItem.icone} dropdown__icone`}
                                  />
                                  {subMenuItem.nome}
                                </BotaoComTexto>
                              </li>
                            ),
                        )}
                      </ul>
                    </li>
                  ) : (
                    <li key={menuItem.id}>
                      <button
                        type="button"
                        className={clsx('cabecalho-sistema__item-de-menu', {
                          'cabecalho-sistema__item-de-menu_ativo': getMenuAtivo(
                            menuItem.filtro,
                          ),
                        })}
                        aria-label=""
                        aria-haspopup="true"
                        onClick={handleNavigate(menuItem.path)}
                      >
                        <div role="presentation">{menuItem.nome}</div>
                      </button>
                    </li>
                  )}
                </ul>
              ))}
            </nav>
          )}
        </div>
        <div className="cabecalho-sistema__container-secundario">
          <nav className="cabecalho-sistema__menu" aria-label="Menu secundário">
            <ul className="cabecalho-sistema__lista-de-itens">
              <li className="dropdown dropdown_direita">
                <button
                  type="button"
                  className="cabecalho-sistema__item-de-menu"
                  aria-label="Opções para o usuário Administrador"
                  aria-haspopup="true"
                >
                  <span className="cabecalho-sistema__icone-do-usuario">
                    <i className="far fa-user" />
                  </span>
                  <span className="cabecalho-sistema__nome-do-usuario">
                    {usuario?.name}
                  </span>
                  <i className="far fa-angle-down u-margem-esquerda-pequena" />
                </button>
                <ul
                  className={clsx('dropdown__lista', classes.submenu)}
                  aria-label="Submenu"
                >
                  {memoSubMenuItemsSecundario.map(subMenuItem => (
                    <li className="dropdown__item" key={subMenuItem.id}>
                      <BotaoComTexto
                        classesAuxiliares="dropdown__link"
                        visual="sem-preenchimento"
                        onClick={handleNavigate(subMenuItem.path)}
                      >
                        <i className="fa-regular fa-cog dropdown__icone" />
                        {subMenuItem.nome}
                      </BotaoComTexto>
                    </li>
                  ))}
                  <li className="dropdown__item">
                    <a
                      className="dropdown__link"
                      target="_blank"
                      rel="nofollow noreferrer"
                      href={`https://termos.digix.com.br/politicasDePrivacidade?produto=${process.env.REACT_APP_PRIVACY_POLICY_ENV}`}
                    >
                      <i className="fa-regular fa-lock dropdown__icone" />
                      Politica de privacidade
                    </a>
                  </li>
                  <li className="dropdown__item">
                    <a
                      className="dropdown__link"
                      target="_blank"
                      rel="nofollow noreferrer"
                      href={`https://termos.digix.com.br/termos/visualizacao?produto=${process.env.REACT_APP_PRIVACY_POLICY_ENV}`}
                    >
                      <i className="fa-regular fa-lock dropdown__icone" />
                      Termos
                    </a>
                  </li>
                  <li className="dropdown__item">
                    <BotaoComTexto
                      classesAuxiliares="dropdown__link"
                      visual="sem-preenchimento"
                      onClick={() => navigate('/app/aplicativos')}
                      habilitado={location.pathname !== '/app/aplicativos'}
                    >
                      <i className="fa-regular fa-computer-classic dropdown__icone" />
                      Aplicativos
                    </BotaoComTexto>
                  </li>
                  <li className="dropdown__item">
                    <BotaoComTexto
                      classesAuxiliares="dropdown__link"
                      visual="sem-preenchimento"
                      onClick={deslogar}
                    >
                      <i className="far fa-sign-out dropdown__icone" />
                      Sair
                    </BotaoComTexto>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <Outlet />
    </>
  );
}
